import React, { Component } from 'react';
import { Dimmer, Image, Reveal} from 'semantic-ui-react';

import './GraphicDesign.css';

export default class ImageDisplay extends Component {
	state = {};

	handleOpen = () => this.setState({ active: true });
	handleClose = () => this.setState({ active: false });
	changeBackground = (e) => {
		// e.target.style.opacity == 0.5 ? e.target.style.opacity = 1 : e.target.style.opacity = 0.5
		
	  }
	render() {
		const { active } = this.state;

		return (
			<div>
				
				<div
					className="imageHeight"
					style={{ backgroundImage: `url(${this.props.imgage})` }}
					onClick={this.handleOpen} >
						<div onMouseOver={this.changeBackground} onMouseLeave={this.changeBackground} className="imageHeightHidden">
							{/* {this.props.Image_Description} */}
						</div>
					</div>
				<Dimmer active={active} onClickOutside={this.handleClose} page>
					<Image
						src={this.props.imgage}
						className="displayedImage"
                        onClick={this.handleClose}
					/>
				</Dimmer>
			</div>
		);
	}
}
