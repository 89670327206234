import React, { Component } from 'react';
import './Home.css'
import '../Animation/animation.css'
// import background from "../../images/mirrored.png"
import background from "../../images/hepburnorg.png"

import { MDBAnimation } from 'mdb-react-ui-kit';
// import background from "../../images/background2a.jpeg"
class Home extends Component {
    render() {
        return (
         
          <MDBAnimation
          reset={true}
          repeatOnScroll
          animation='fade-in'
          start='onLoad'
          duration={1000}>  
          <div className="homeBackground" style={{ backgroundImage: `url(${background})` }}>
          

                <div className="title">
                <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onLoad'
  duration={2000}
  delay={500}>  
                <h1 className="largeTitleHeader">Paula Bannerman</h1>
                <p className="TitleHeaderSM">Developer, Artist, Designer, and Educator</p>
                </MDBAnimation>
                </div>
               
            </div> 
            </MDBAnimation>
          //  </div>
            
        );
    }
}

export default Home;