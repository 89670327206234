import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
class ButtonComp extends Component {
    render() {
        if(this.props.url){
            return (
                <div>
                    <Button size='mini'>{this.props.buttonmessage}</Button>
                </div>
            );
        } else {
            return <div></div>
        }
       
    }
}

export default ButtonComp;