import React, { Component } from 'react'
import {  Divider } from 'semantic-ui-react'
import Display from "./StemDisplay"
import stem from "../../Data/stemimages.json"
export default class AboutStem extends Component {
    render() {
        return (
            <div className="Stem">
                
 <Divider />
 <p>Born and raised in the Washington DC metro area, I'm an artist and software engineer with 30 years of programming experience. My passion lies with encouraging others to find their passions and get them to the next level, especially in Art and STEAM/STEM-related fields.</p>
 <p>
 Art is a very powerful and an underestimated tool, especially with the STEM movement. Art is imagination, inspiration, support, creation, and also a communications tool. I believe that programming, science, engineering is all forms of art that are usable instead of just being placed in a museum. 
 </p>
 <div class="stemGallery">
 {stem.map((item, index)=><Display {...item} key={index}></Display>)}
 </div>
            </div>
        )
    }
}
