import React, { Component } from 'react';
import ContactCard from "./ContactCard"
import './Contact.css'
class Contact extends Component {
    render() {
        return (
            <div className="contactDisplay">
                <ContactCard></ContactCard>
                
            </div>
        );
    }
}

export default Contact;