import React, { Component } from 'react'
import {  Divider } from 'semantic-ui-react'
import cv from "../../Data/cv.json"

import { MDBAnimation } from 'mdb-react-ui-kit';
export default class Cv extends Component {
    render() {
        return (
            <div>
 <Divider />
 <div className="artistcv">
                {cv.map((item, index)=>(<div key={index} className="cvItem">
                <MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onScroll'
      duration={1000}
    >
                    <h5>{item.name}</h5><p>{item.date}<br/>{item.location}</p>
                    </MDBAnimation>
                    </div>))}
                </div>
            </div>
        )
    }
}
