import React, { Component } from 'react';
import resumeData from '../../Data/resume.json';
import {  Divider } from 'semantic-ui-react'
import Header from "../Header/Header"

import { MDBAnimation } from 'mdb-react-ui-kit';

class Education extends Component {
    render() {
        return (
            <div>
             {resumeData[0].Education.map((item, index) => (
					<div key={index}>
                        <MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onScroll'
      duration={1000}
    >
                        <Divider />
                        <Header size={'medium'} color={"red"} info={item.Program}></Header>
                        <p>{item.Name}</p>
                        <span>{item.Dates}</span>
                        <article>
							{item.Details}
						</article>
                        
</MDBAnimation>
					</div>
				))}   
            </div>
        );
    }
}

export default Education;