import React from 'react';
import { Card, Image, Segment } from 'semantic-ui-react';
import ButtonComp from '../Button/Button';
import "./Card.css"

const CardComp = (props) => (
	<Card style={{ width: '90%' }} className="cardSize">
		{/* <Image src={process.env.PUBLIC_URL + '/images/screenshots/' + props.ImageUrl} className="cardImage"wrapped ui={false} /> */}
		<a href={props.Url} target="_blank" rel="noopener noreferrer">
			<div
				className="imageHeight"
				style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/screenshots/' + props.ImageUrl})` }}
			/>
		</a>
		<Card.Content>
			<Card.Header >
				<a href={props.Url} target="_blank" rel="noopener noreferrer">
					{props.Name}
				</a>
			</Card.Header>
			{/* Returns list of items with commas */}
			<Card.Meta>{props.ToolsUsed.map((item, index) => {
				if (props.ToolsUsed[index + 1]) {
					return (`${item}, `)
				} else {
					return `${item}`
				}
			})}</Card.Meta>
			<Card.Description>{props.Description}</Card.Description>
		</Card.Content>
		<Card.Content extra className="bottomLinks">
			<Segment.Group horizontal className="noBorder">
				<Segment compact className="noBorder">
					<a href={props.Url} target="_blank" rel="noopener noreferrer">
						<ButtonComp buttonmessage={'Website'} url={props.Url} />
					</a>
				</Segment >
				<Segment compact className="noBorder">
					<a href={props.Github} target="_blank" rel="noopener noreferrer">
						<ButtonComp buttonmessage={'Github Repo'} url={props.Github} />
					</a>
				</Segment>
			</Segment.Group>
		</Card.Content>
	</Card>
);

export default CardComp;
