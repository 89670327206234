import React, { Component } from 'react';
import videos from "../../../Data/videos.json"
import VideoPlayer from "./VideoPlayer"

import "./Video.css"
class Videos extends Component {
    render() {
        let count = 0
        let countname = ''
        return (
            
            <div className="videoDisplay">
                {videos.map((item, index)=>{
                    if (count < 4){
                        count++
                        countname = `Click${count}`
                        // console.log(`${process.env.PUBLIC_URL + '/images/click/ClicktoPlay' + count +'.png'}`)
                        return (
                        <div key={index}><VideoPlayer {...item} count = {count} placeholder={countname}></VideoPlayer></div>)
                    } else {
                        count = 0
                        // console.log(`${process.env.PUBLIC_URL + '/images/click/ClicktoPlay' + count +'.png'}`)
                        return <div key={index}><VideoPlayer count = {count} {...item} placeholder={`${process.env.PUBLIC_URL + '/images/click/ClicktoPlay' + count}`}></VideoPlayer></div>
                    }
                })}
            </div>
        );
    }
}

export default Videos;