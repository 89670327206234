import React, { Component } from 'react';
import { Dimmer, Image } from 'semantic-ui-react';

import '../GraphicDesign/GraphicDesign.css';

export default class ImageDisplay extends Component {
	state = {};

	handleOpen = () => this.setState({ active: true });
	handleClose = () => this.setState({ active: false });

	render() {
		const { active } = this.state;

		return (
			<div>
				<div
					className="imageHeight"
					style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/artwork/' + this.props.ImgageThumb})` }}
					onClick={this.handleOpen}
				/>
				<Dimmer active={active} onClickOutside={this.handleClose} page>
					<Image
						src={process.env.PUBLIC_URL + '/images/artwork/' + this.props.ImageFile}
                        className="displayedImage"
                        onClick={this.handleClose}
					/>
				</Dimmer>
			</div>
		);
	}
}
