import React from 'react';
import resumeData from '../../Data/resume.json';
import {  Divider } from 'semantic-ui-react'
import './Resume.css';

import { MDBAnimation } from 'mdb-react-ui-kit';

const Skills = () => (
  <div>
 <Divider />
	<div className="skillListing">
   
		{resumeData[0].Skills.map((item, index) => (
			<div key={index}>
				<div>{item}</div>
			</div>
		))}
    
	</div>
  </div>
);
export default Skills;
