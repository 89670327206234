import React, { useEffect, useState } from 'react';
import graphics from "../../../Data/graphicdesign.json"
import ImageDisplay from "./ImageDisplay"

// import "./GraphicDesign.css"
// class GraphicDesign extends Component {
//     render() {
//         return (
//             <div className="graphicDisplay">
//                 {graphics.map((item, index)=><ImageDisplay {...item} key={index}></ImageDisplay>)}
//             </div>
//         );
//     }
// }

// export default GraphicDesign;


export default function Photography() {
    // const getData = () => {
        // fetch('https://jsonplaceholder.typicode.com/users')
        //   .then((res) => res.json())
        //   .then((res) => {
        //     console.log(res)
        //     fetchUsers(res)
        //   })

    //     axios({
    //         url: 'https://1jzxrj179.lp.gql.zone/graphql',
    //         method: 'post',
    //         data: {
    //           query: `
    //             query PostsForAuthor {
    //               author(id: 1) {
    //                 firstName
    //                   posts {
    //                     title
    //                     votes
    //                   }
    //                 }
    //               }
    //             `
    //         }
    //       }).then((result) => {
    //         console.log(result.data)
    //       });
    //   }
    
    //   useEffect(() => {
    //     getData()
    //   }, [])
    

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }
      
      const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
      // console.log(images)
      const keys = Object.keys(images);

      const entries = Object.entries(images);
// console.log(entries[0][1]);
  return (
    <div className="graphicDisplay">
      {/* {keys.forEach((key, index) => {
    <p>{key} {images[key]}</p>
})} */}
                {entries.map((item, index)=><ImageDisplay imgage={item[1]} key={index}></ImageDisplay>)}
           </div>
  )
}
