import React, { Component } from 'react';
import { Dimmer, Image, Reveal} from 'semantic-ui-react';

import './GraphicDesign.css';

export default class ImageDisplay extends Component {
	state = {};

	handleOpen = () => this.setState({ active: true });
	handleClose = () => this.setState({ active: false });
	changeBackground = (e) => {
		e.target.style.opacity == 0 ? e.target.style.opacity = 1 : e.target.style.opacity = 0
		
	  }
	render() {
		const { active } = this.state;

		return (
			<div>
				
				<div
					className="imageHeight"
					style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/' + this.props.ImgageThumb})` }}
					onClick={this.handleOpen} >
						<div onMouseOver={this.changeBackground} onMouseLeave={this.changeBackground} className="imageHeightHidden">
							{this.props.Image_Description}
						</div>
					</div>
				<Dimmer active={active} onClickOutside={this.handleClose} page>
					<Image
						src={process.env.PUBLIC_URL + '/images/' + this.props.ImageFile}
						className="displayedImage"
                        onClick={this.handleClose}
					/>
				</Dimmer>
			</div>
		);
	}
}
