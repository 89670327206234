import React from 'react'
import HeaderLrg from '../Header/HeaderLrg'
import Header from "../Header/Header"
import { Divider } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import HeaderMd from '../Header/HeaderMd'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';

export default function Education() {
  return (
    <div className="aboutSection">
         <HeaderLrg title="Education"></HeaderLrg>
         <h4 className="mb-5">This section contains educational items that I have created for artists, designers, and developers.</h4>
         {/* <hr></hr> */}
        <MDBContainer>
        <Divider section />
        <MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onLoad'
      duration={1000}
      className='bottom-margin'
      
    >
            <MDBRow>
                <MDBCol className="d-flex align-items-center flex-column" md="6" sm='12'>
                <HeaderMd title="Life Debugger"></HeaderMd>
<h5 className="mb-5">
        {/* <MDBBtn tag="a" href="#!" class="stretched-link">Go somewhere</MDBBtn> */}
                <a href="https://www.lifedebugger.dev/" target="_blank" rel="noopener noreferrer" className="text-dark

">lifedebugger.dev</a></h5>
                
                    <p>A resource listing for developers that includes interview questions and how to present.</p>
                    <a href="https://www.lifedebugger.dev/" target="_blank" rel="noopener noreferrer">
                    <Button size='medium'>Go to site <MDBIcon fas icon="external-link-alt" /></Button></a>
                </MDBCol>
                <MDBCol>
                    <a href="https://www.lifedebugger.dev/" target="_blank" rel="noopener noreferrer">
                <img
      src={`${process.env.PUBLIC_URL + '/images/screenshots/README-LifeDebugger-dev.png'}`}
      className='img-fluid shadow-5'
      alt='Life Debugger'
    />
</a>
                </MDBCol>
            </MDBRow>
            </MDBAnimation>
            <Divider section />
{/* ANCHOR IF ELSE */}

<MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onLoad'
      duration={1000}
    delay={500}
      className='bottom-margin'
    >
            <MDBRow>
            <MDBCol sm='12'  md="6" >
                    <a href="https://ifelse.dcartist.studio/" target="_blank" rel="noopener noreferrer">
                <img
      src={`${process.env.PUBLIC_URL + '/images/screenshots/if-else.png'}`}
      className='img-fluid shadow-5'
      alt='IF Else Statement'
    />
</a>
                </MDBCol>
                <MDBCol className="d-flex align-items-center flex-column">
                <HeaderMd title="IF/ElSE"></HeaderMd>
<h5 className="mb-5">
                <a href="https://ifelse.dcartist.studio/" target="_blank" rel="noopener noreferrer" className="text-dark

">ifelse.dcartist.studio</a></h5>
                
                    <p>A presentation on the introduction into IF ELSE statements in Javascript and Python</p>
                    <a href="https://ifelse.dcartist.studio/" target="_blank" rel="noopener noreferrer">
                    <Button size='medium'>Go to site <MDBIcon fas icon="external-link-alt" /></Button></a>
                </MDBCol>
                
            </MDBRow>
            </MDBAnimation>


            <Divider section />

{/* ANCHOR ARRAY */}

            <MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onScroll'
      duration={1000}
      className='bottom-margin'
      
    >
            <MDBRow>
                <MDBCol className="d-flex align-items-center flex-column" sm='12'  md="6" >
                <HeaderMd title="Pokemon Array"></HeaderMd>
<h5 className="mb-5">
                <a href="https://pokemonarray.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-dark

">pokemonarray.vercel.app</a></h5>
                
                    <p>A presentaion on how arrays work and some of the array methods</p>
                    <a href="https://pokemonarray.vercel.app/" target="_blank" rel="noopener noreferrer">
                    <Button size='medium'>Go to site <MDBIcon fas icon="external-link-alt" /></Button></a>
                </MDBCol>
                <MDBCol>
                    <a href="https://pokemonarray.vercel.app/" target="_blank" rel="noopener noreferrer">
                <img
      src={`${process.env.PUBLIC_URL + '/images/screenshots/Pokemon-Array.png'}`}
      className='img-fluid shadow-5'
      alt='Pokemon Array'
    />
</a>
                </MDBCol>
            </MDBRow>
            </MDBAnimation>


            <Divider section />

            {/* ANCHOR OBJECTS */}
            <MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onScroll'
      duration={1000}
    // delay={100}
      className='bottom-margin'
    >
            <MDBRow>
            <MDBCol sm='12' md="6">
                    <a href="https://objects.vercel.app/" target="_blank" rel="noopener noreferrer">
                <img
      src={`${process.env.PUBLIC_URL + '/images/screenshots/objects.png'}`}
      className='img-fluid shadow-5'
      alt='Life Debugger'
    />
</a>
                </MDBCol>
                <MDBCol className="d-flex align-items-center flex-column">
                <HeaderMd title="{ Objects }"></HeaderMd>
<h5 className="mb-5">
        {/* <MDBBtn tag="a" href="#!" class="stretched-link">Go somewhere</MDBBtn> */}
                <a href="https://objects.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-dark

">objects.vercel.app</a></h5>
                
                    <p>A presentation on the introduction into javascript objects</p>
                    <a href="https://objects.vercel.app/" target="_blank" rel="noopener noreferrer">
                    <Button size='medium'>Go to site <MDBIcon fas icon="external-link-alt" /></Button></a>
                </MDBCol>
                
            </MDBRow>
            </MDBAnimation>
            <Divider section />
        </MDBContainer>
    </div>
  )
}
