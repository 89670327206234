import '../../Components/Portfolio/GraphicDesign/GraphicDesign.css';
import React, { Component } from 'react';
import { Dimmer, Image, Reveal} from 'semantic-ui-react';

import { MDBAnimation } from 'mdb-react-ui-kit';

export default class ImageDisplay extends Component {
	state = {};

	handleOpen = () => this.setState({ active: true });
	handleClose = () => this.setState({ active: false });
	changeBackground = (e) => {
		e.target.style.opacity == 0 ? e.target.style.opacity = 1 : e.target.style.opacity = 0
		
	  }
	render() {
		const { active } = this.state;

		return (
			<div>
				
				<div
					className="imageHeight-stem"
					style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/stem/' + this.props.ImgageThumb})` }}
					onClick={this.handleOpen} >
						<MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onScroll'
      duration={1000}
    >
						<div onMouseOver={this.changeBackground} onMouseLeave={this.changeBackground} className="imageHeightHidden">
							{this.props.description}
						</div></MDBAnimation>
					</div>
				<Dimmer active={active} onClickOutside={this.handleClose} page>
					<Image
						src={process.env.PUBLIC_URL + '/images/stem/' + this.props.Imageurl}
						className="displayedImage"
                        onClick={this.handleClose}
					/>
				</Dimmer>
			</div>
		);
	}
}
