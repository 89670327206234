import React from 'react'
import { Embed, Card } from 'semantic-ui-react'

const VideoPlayer = (props) => (
<div>

  <Card fluid color='red' header='Option 1' >
  <Card.Content>
<Card.Header>{props.Name}</Card.Header>
        <Card.Description>
        <Embed
    id={props.VideoId}
    placeholder={`./images/click/ClicktoPlay${props.count}.png`}
    source='vimeo'
  />
          {props.Description}
        </Card.Description>
  </Card.Content>
  </Card>
</div>
)

export default VideoPlayer
