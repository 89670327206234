import React from 'react'
import { List } from 'semantic-ui-react'

const ContactCard = () => (
  <List divided size={"large"}>
    <List.Item>
      <List.Icon name='info' />
      <List.Content>Paula Bannerman</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='marker' />
      <List.Content>Washington, DC Metro Area</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='mail' />
      <List.Content>
        pbannerman13@gmail.com
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='github alternate' />
      <List.Content>
        <a href='https://github.com/dcartist' target="_blank" rel="noopener noreferrer">github.com/dcartist</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='linkedin' />
      <List.Content>
        <a href='https://www.linkedin.com/in/dcartist/'>linkedin.com/in/dcartist</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='instagram' />
      <List.Content>
        <a href='https://www.instagram.com/dcartist/'>instagram.com/dcartist</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='twitter' />
      <List.Content>
        <a href='https://twitter.com/dcartist'mtarget="_blank" rel="noopener noreferrer">twitter.com/dcartist</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='twitch' />
      <List.Content>
        <a href='https://twitch.com/dcartist'mtarget="_blank" rel="noopener noreferrer">twitch.com/dcartist</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='world' />
      <List.Content>
        <a href='https://www.dcartist.com' target="_blank" rel="noopener noreferrer">dcartist.com</a>
      </List.Content>
    </List.Item>
  </List>
)

export default ContactCard
