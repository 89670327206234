import React, { Component } from 'react';
import resumeData from '../../Data/resume.json';
import {  Divider } from 'semantic-ui-react'
import Header from "../Header/Header"

import { MDBAnimation } from 'mdb-react-ui-kit';

class Resume2020 extends Component {
	render() {
		return (
			<div>
				{resumeData[0].Employment.map((item, index) => (
					<div key={index}>
						<MDBAnimation
      reset={true}
      repeatOnScroll
      animation='fade-in'
      start='onScroll'
      duration={1000}
    >
                        <Divider />
                        <Header size={'medium'} color={"red"} info={item.Title}></Header>
                        <p>{item.Company}</p>
                        <span>{item.Dates}</span>
                        <ul>
						{item.Details.map((detail) => <li>{detail}</li>)}
                        </ul>
                        
</MDBAnimation>
					</div>
				))}
				
			</div>
		);
	}
}

export default Resume2020;
