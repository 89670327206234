import React, { Component } from 'react';
import portfolioInfo from '../../../Data/projects.json';
import Card from '../../Card/Card';
import ItemCard from '../../Card/Item';
import Category from "./Category"

import { Select, Form } from 'semantic-ui-react'

import {MDBContainer,  MDBRow, MDBCol, MDBAnimation } from 'mdb-react-ui-kit';
import './AppWeb.css';


const countryOptions = [
	{ key: 'Javascript', value: 'Javascript', text: 'Javascript' },
	{ key: 'Database', value: 'Database', text: 'Database & APIs' },
	{ key: 'CSS', value: 'CSS', text: 'CSS' },
	{ key: 'ReactJS', value: 'ReactJS', text: 'ReactJS' },
	{ key: 'Angular', value: 'Angular', text: 'Angular' },
	{ key: 'PHP', value: 'PHP', text: 'PHP' },
	{ key: 'Python', value: 'Python', text: 'Python' },
	{ key: 'Show All', value: 'Show All', text: 'Show All' }
  ]

class Appsweb extends Component {
	constructor(){
		super()
		this.state={
			category:""
		}
	}



	 thisIsChange = (e, value) =>{
		
		if (value.value == "Show All"){
			this.setState({category:""})
		} else {
			this.setState({ category: value.value })
		}
    }
	categoryChange = (name) =>{
		name === "Show All" ? this.setState({category:""}) : this.setState({category:name})
	}
	render() {
		return (
			<div>
			<MDBContainer>
				<MDBRow>
					<MDBCol>
					<Form>
						
    <Form.Select
	
	label={`Category`}
    placeholder='Category'
    options={countryOptions}
	onChange={(e, value)=>this.thisIsChange(e, value)}
	>
      
    </Form.Select>
  </Form>


					</MDBCol>
				</MDBRow>
			</MDBContainer>
				
<MDBContainer>
	<MDBRow>
		<MDBCol className="pt-2">  {
		this.state.category.length > 0 ?  <p>Results: {portfolioInfo.filter((portfolio) => portfolio.ToolsUsed.includes(this.state.category)).length} </p>
	:  <p>Total: {portfolioInfo.length}</p>
	
	}</MDBCol>
	</MDBRow>
</MDBContainer>

				<div className="appDisplay">
					
					{ this.state.category.length == 0 ? (portfolioInfo.filter((portfolio) => portfolio.Name.length > 0).map((item, index) => (
					<Card {...item} key={index}></Card>
					))) : (portfolioInfo.filter((portfolio) => portfolio.Name.length > 0 && portfolio.ToolsUsed.includes(this.state.category)).map((item, index) => {
							return  <Card {...item} key={index} />
						}))}
					
					
					

					
				</div>
			</div>
		);
	}
}

export default Appsweb;
