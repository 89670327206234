import React, { Component } from 'react';
import artwork from "../../../Data/artwork.json"
import ImageDisplay from "./ArtDisplay"

class Artwork extends Component {
    render() {
        return (
            <div className="graphicDisplay">
                {artwork.map((item, index)=><ImageDisplay {...item} key={index}></ImageDisplay>)}
            </div>
        );
    }
}

export default Artwork;