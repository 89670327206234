import React, { Component } from 'react';
import graphics from "../../../Data/graphicdesign.json"
import ImageDisplay from "./ImageDisplay"

import "./GraphicDesign.css"
class GraphicDesign extends Component {
    render() {
        return (
            <div className="graphicDisplay">
                {graphics.map((item, index)=><ImageDisplay {...item} key={index}></ImageDisplay>)}
            </div>
        );
    }
}

export default GraphicDesign;