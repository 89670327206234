import React, { Component } from 'react';
import Skills from "./Skills"
import Resume2022NoAnim from "./Resume2022NoAnim"
import Education from "./Education"
import HeaderLrg from '../Header/HeaderLrg'
import HeaderMd from '../Header/HeaderMd'
import './Resume.css'

import { MDBAnimation } from 'mdb-react-ui-kit';
class Resume extends Component {
    render() {
        return (
            <div className="ResumeBackground">
                <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onLoad'
  duration={1000}
  className='bottom-margin1'
>
                <HeaderMd title="Skills"></HeaderMd>
                <Skills className="mb-5"></Skills>
                </MDBAnimation>
                <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onLoad'
  duration={1000}
  delay={300}
  
>
                <HeaderMd title="Employment"></HeaderMd>
                </MDBAnimation>
               <Resume2022NoAnim></Resume2022NoAnim>
                <HeaderMd title="Education"></HeaderMd>
                <Education></Education>
            </div>
        );
    }
}

export default Resume;