import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
class NavigationBar extends Component {
	render() {
		return (
				<Navbar collapseOnSelect expand="lg" className="navBackground">
					<Navbar.Brand href="/">Paula Bannerman</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							{/* <Nav.Link href="/" className="NavItem">
								Home
							</Nav.Link> */}
							<Nav.Link as={Link} eventKey={2} to="/" className="NavItem">
								Home
							</Nav.Link>
							<Nav.Link as={Link} eventKey={2} to="/about">
								About
							</Nav.Link>
							<NavDropdown title="Portfolio" id="basic-nav-dropdown">
          <NavDropdown.Item href="/apps">Apps & Web</NavDropdown.Item>
          <NavDropdown.Item href="/artwork">Artwork</NavDropdown.Item>
          <NavDropdown.Item href="/graphicdesign">Graphic Design</NavDropdown.Item>
          <NavDropdown.Item href="/photography">Photography</NavDropdown.Item>
          <NavDropdown.Item href="/video">Video</NavDropdown.Item>
          {/* <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
        </NavDropdown>
							{/* <Nav.Link as={Link} eventKey={2} to="/apps">
								Apps & Web
							</Nav.Link>
							<Nav.Link as={Link} eventKey={2} to="/artwork">
								Artwork
							</Nav.Link>
							<Nav.Link as={Link} eventKey={2} to="/graphicdesign">
								Graphic Design
							</Nav.Link>
							<Nav.Link as={Link} eventKey={2} to="/video">
								Video
							</Nav.Link> */}
							<Nav.Link as={Link} eventKey={2} to="/education">
								Education
							</Nav.Link>
							<Nav.Link as={Link} eventKey={2} to="/resume">
								Resume
							</Nav.Link>
							
						<Nav.Link as={Link} eventKey={2} to="/contact">
							Contact
							</Nav.Link>
						</Nav>
						<Nav>
							
							
						</Nav>
					</Navbar.Collapse>
				</Navbar>
		);
	}
}

export default NavigationBar;
