import React, { useRef }  from 'react';
import Pic from '../../images/paula2019.jpg'
// import BackToTop from 'react-back-to-top';
import './About.css'
import CV from "./Cv"
import engaged from "../../images/engaged.jpg"
import Stem from "./AboutStem"
import HeaderLrg from '../Header/HeaderLrg'
import Header from "../Header/Header"
import HeaderMd from '../Header/HeaderMd'
import Resume from '../Resume/Resume2020'
import Education from '../Resume/Education'
import Skills from '../Resume/Skills'
import {  Divider } from 'semantic-ui-react'
import { MDBScrollStatus } from 'mdb-react-scroll-status';
import { MDBAnimation } from 'mdb-react-ui-kit';
// import React from 'react'

export default function About() {
    const exampleReference = useRef(null);
    return (
            
        <div className="aboutSection" ref={exampleReference}>
            {/* <MDBScrollStatus global className="sticky-top"/> */}
            <HeaderLrg title="About"></HeaderLrg>
            <section className="profile bottomSection">
        <article>
        <MDBAnimation
reset={true}
repeatOnScroll
animation='fade-in'
start='onLoad'
duration={2000}
>
        
        <img src={Pic} alt="Paula Bannerman"/> </MDBAnimation>
        </article>
        <article className="Statement">
        <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onLoad'
  duration={1000}
>
        Creative and thirsty for knowledge, I am an artistic software engineer who combines the technical background of code with the flavor of art. I see beyond the screen to bring ideas, concepts, and canvas’ to life. <br></br> 
        <p>
        - PAULA BANNERMAN
        </p>
       </MDBAnimation>
        </article>
        <p></p>
            </section>
        <section className="bottomSection">
        <MDBAnimation
reset={true}
repeatOnScroll
animation='fade-in'
start='onLoad'
duration={1000}
delay={600}
>
        <HeaderMd title="Artist in STEM/STEAM"></HeaderMd>
        <article className="aboutSections">
            
        <Stem></Stem>
        </article>
        </MDBAnimation>
        </section>
            <section id="skills" className="bottomSection">
            <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onScroll'
  duration={1000}
>
            <HeaderMd title="Skills"></HeaderMd>
            <article className="aboutSections">
            <Skills></Skills>
            </article>
            </MDBAnimation>
            </section>
            <section>

            </section>
            <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onScroll'
  duration={500}
>
            <HeaderMd title="Resume"></HeaderMd>
            <section className="aboutSections bottomSection">
                <Resume></Resume>
            </section>
           </MDBAnimation> 
            <section>

            <HeaderMd title="Education"></HeaderMd>
            </section>
            <section className="aboutSections bottomSection">
                <Education></Education>
            </section>
            <section>

            <HeaderMd title="Publications"></HeaderMd>
            </section>
            <section className="aboutSections bottomSection">
            <Divider />
            <div className="publication">
                <div>
                <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onScroll'
  duration={1000}
>
                <Header size={'medium'} color={"red"} info={"Comics DC"} className="publicationHeader"></Header>
                <p>April, 2017 <br></br>  Artist Interview @ Artomatic </p>
                <Header size={'medium'} color={"red"} info={"Washington Informer"} className="publicationHeader"></Header>
                    <p>May, 2016 <br></br>  Artist @ Tee Con </p>
                <Header size={'medium'} color={"red"} info={"Art & Design Inspiration"} className="publicationHeader"></Header>
                    <p>November, 2014 <br></br>  Art & Design on the Go </p>
                <Header size={'medium'} color={"red"} info={"Enkoart"} className="publicationHeader"></Header>
                    <p>May 2014 <br></br> Interview as DCartist</p>
                <Header size={'medium'} color={"red"} info={"Art Beyond 2014 Special Edition"} className="publicationHeader"></Header>
                    <p>April, 2014 <br></br> Interview as DCartist</p>
                <Header size={'medium'} color={"red"} info={"Engaged! Magazine"} className="publicationHeader"></Header>
                    <p>Fall 2012 <br></br> Destination Mexico - (Jewlery Design)</p>
                    </MDBAnimation>
                </div>
                <div> <MDBAnimation
  reset={true}
  repeatOnScroll
  animation='fade-in'
  start='onScroll'
  duration={3000}
><img src={engaged} className="engaged"/></MDBAnimation></div>
            </div>
            </section>
            <section id="cv" className="bottomSection">
            <HeaderMd title="Artist CV"></HeaderMd>
            <article className="aboutSections">
       <CV></CV>
        </article>
            </section>
        </div>
    );
  
}

// class About extends Component {
//     render() {
//         return (
            
//             <div className="aboutSection">
//                 <BackToTop
//     mainStyle={{
//         width:'100%',
//         height:'100%',
//         background:'url(...)'
//     }}
//     percentStyle={{
//         width:'100%',
//         height:'100%',
//     }}
//     animate='rotate'
//     offsetTop={20}
//     step={50}
//     percent={true}
//     visiblePercent={50}
//   />
//                 <HeaderLrg title="About"></HeaderLrg>
//                 <section className="profile bottomSection">
//             <article>
//             <MDBAnimation
//   reset={true}
//   repeatOnScroll
//   animation='fade-in'
//   start='onLoad'
//   duration={2000}
// >
            
//             <img src={Pic} alt="Paula Bannerman"/> </MDBAnimation>
//             </article>
//             <article className="Statement">
//             <MDBAnimation
//       reset={true}
//       repeatOnScroll
//       animation='fade-in'
//       start='onLoad'
//       duration={1000}
//     >
//             Creative and thirsty for knowledge, I am an artistic software engineer who combines the technical background of code with the flavor of art. I see beyond the screen to bring ideas, concepts, and canvas’ to life. <br></br> 
//             <p>
//             - PAULA BANNERMAN
//             </p>
//            </MDBAnimation>
//             </article>
//             <p></p>
//                 </section>
//             <section className="bottomSection">
//             <MDBAnimation
//   reset={true}
//   repeatOnScroll
//   animation='fade-in'
//   start='onLoad'
//   duration={1000}
//   delay={600}
// >
//             <HeaderMd title="Artist in STEM/STEAM"></HeaderMd>
//             <article className="aboutSections">
                
//             <Stem></Stem>
//             </article>
//             </MDBAnimation>
//             </section>
//                 <section id="skills" className="bottomSection">
//                 <MDBAnimation
//       reset={true}
//       repeatOnScroll
//       animation='fade-in'
//       start='onScroll'
//       duration={1000}
//     >
//                 <HeaderMd title="Skills"></HeaderMd>
//                 <article className="aboutSections">
//                 <Skills></Skills>
//                 </article>
//                 </MDBAnimation>
//                 </section>
//                 <section>

//                 </section>
//                 <MDBAnimation
//       reset={true}
//       repeatOnScroll
//       animation='fade-in'
//       start='onScroll'
//       duration={500}
//     >
//                 <HeaderMd title="Resume"></HeaderMd>
//                 <section className="aboutSections bottomSection">
//                     <Resume></Resume>
//                 </section>
//                </MDBAnimation> 
//                 <section>

//                 <HeaderMd title="Education"></HeaderMd>
//                 </section>
//                 <section className="aboutSections bottomSection">
//                     <Education></Education>
//                 </section>
//                 <section>

//                 <HeaderMd title="Publications"></HeaderMd>
//                 </section>
//                 <section className="aboutSections bottomSection">
//                 <Divider />
//                 <div className="publication">
//                     <div>
//                     <MDBAnimation
//       reset={true}
//       repeatOnScroll
//       animation='fade-in'
//       start='onScroll'
//       duration={1000}
//     >
//                     <Header size={'medium'} color={"red"} info={"Comics DC"} className="publicationHeader"></Header>
//                     <p>April, 2017 <br></br>  Artist Interview @ Artomatic </p>
//                     <Header size={'medium'} color={"red"} info={"Washington Informer"} className="publicationHeader"></Header>
//                         <p>May, 2016 <br></br>  Artist @ Tee Con </p>
//                     <Header size={'medium'} color={"red"} info={"Art & Design Inspiration"} className="publicationHeader"></Header>
//                         <p>November, 2014 <br></br>  Art & Design on the Go </p>
//                     <Header size={'medium'} color={"red"} info={"Enkoart"} className="publicationHeader"></Header>
//                         <p>May 2014 <br></br> Interview as DCartist</p>
//                     <Header size={'medium'} color={"red"} info={"Art Beyond 2014 Special Edition"} className="publicationHeader"></Header>
//                         <p>April, 2014 <br></br> Interview as DCartist</p>
//                     <Header size={'medium'} color={"red"} info={"Engaged! Magazine"} className="publicationHeader"></Header>
//                         <p>Fall 2012 <br></br> Destination Mexico - (Jewlery Design)</p>
//                         </MDBAnimation>
//                     </div>
//                     <div> <MDBAnimation
//       reset={true}
//       repeatOnScroll
//       animation='fade-in'
//       start='onScroll'
//       duration={3000}
//     ><img src={engaged} className="engaged"/></MDBAnimation></div>
//                 </div>
//                 </section>
//                 <section id="cv" className="bottomSection">
//                 <HeaderMd title="Artist CV"></HeaderMd>
//                 <article className="aboutSections">
//            <CV></CV>
//             </article>
//                 </section>
//             </div>
//         );
//     }
// }

// export default About;